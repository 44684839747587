import * as React from 'react';
import Layout from '../../components/layout';
import { Link, graphql, useStaticQuery} from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const BlogPage = ({ data }) => {
  return (
    <Layout pageTitle="Blog">
      <p>I write about technology, culture and leadership. All opinions are my own.</p>
      {
        data.allMdx.nodes.map((node) => (
          <article key={node.id}>
            <h3><Link to={"/blog/" + node.slug}>{node.frontmatter.title}</Link></h3>
            <p>Posted: {node.frontmatter.date}</p>
            <p>{node.excerpt}</p>
          </article>
        ))
      }
    </Layout>
  );
}

export const query = graphql`
query {
  allMdx(sort: {fields: frontmatter___date, order:DESC}, filter: {frontmatter: {draft: {ne: true}}, fileAbsolutePath: {regex: "/(blog)/"}}) {
    nodes {
      frontmatter {
      	title
        date
      }
      excerpt
      slug
    }
  }
}`

export default BlogPage
